import {Controller} from "stimulus"

export default class extends Controller {
  static get targets() {
    return [ "input" ]
  }

  onClick() {
    this.openCheck()
  }

  openCheck() {
    this.inputTargets.forEach(el => {
      el.checked = false
    })
  }

}
