import { Controller } from "stimulus";
import MicroModal from "micromodal";

export default class extends Controller {
  static get targets() {
    return ["modal", "video", "certificateAside", "form", "success"];
  }

  connect() {
    MicroModal.init({
      disableScroll: true,
      onShow: () => this.onModalOpen(),
      onClose: () => this.onModalClose()
    });

    this.checkCertificateStatus();
    const videoId = this.getCurrentVideoId();

    if (!this.getVideoModalStatus(videoId)) {
      this.startCertificateTimer(videoId);
    }
  }

  getCurrentVideoId() {
    return this.data.get("video-id")
  }

  getVideoModalStatus(videoId) {
    const modalStatus = JSON.parse(localStorage.getItem("certificateModalStatus")) || {};
    return modalStatus[videoId];
  }

  setVideoModalStatus(videoId, status) {
    const modalStatus = JSON.parse(localStorage.getItem("certificateModalStatus")) || {};
    modalStatus[videoId] = status;
    localStorage.setItem("certificateModalStatus", JSON.stringify(modalStatus));
  }

  open(event) {
    event.preventDefault();
    MicroModal.show("certificate-modal");
  }

  close(event) {
    event.preventDefault();
    MicroModal.close("certificate-modal");
  }

  onModalClose() {}

  onModalOpen() {}

  startCertificateTimer(videoId) {
    this.certificateTimeout = setTimeout(() => {
      this.setVideoModalStatus(videoId, true);
      this.showCertificateAside();
    }, 1000 * 60 * 15);
  }

  showCertificateAside() {
    this.certificateAsideTarget.classList.remove("hidden");
  }

  checkCertificateStatus() {
    const videoId = this.getCurrentVideoId();
    if (this.getVideoModalStatus(videoId)) {
      this.showCertificateAside();
    }
  }

  submitForm(event) {
    event.preventDefault();

    const formData = new FormData(this.formTarget);
    const url = `/teacher-professional-development/generate-certificate`;

    fetch(url, {
      method: "POST",
      body: formData
    })
        .then(response => {
          if (response.ok) {
            this.displaySuccessMessage();
          } else {
            console.error("Form submission failed.");
          }
        })
        .catch(error => console.error("Error:", error));
  }

  displaySuccessMessage() {
    this.formTarget.classList.add("hidden");
    this.successTarget.classList.remove("hidden");
  }
}
