import {Controller} from "stimulus"
import MicroModal from "micromodal"
import {maskInput} from "vanilla-text-mask"
import * as shared from "controllers-shared"

export default class extends Controller {

  static get targets() {
    return ["keydown"]
  }

  onModalClose() {
    shared.onModalClose(
      "segway-lead-modal",
      this.keydownTarget
    )
  }

  connect() {
    MicroModal.init({
      onClose: () => this.onModalClose()
    });
  }
}
