import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["row"]
  counter = 0

  connect() {
    this.rotateLogos()
  }

  showCls = ["left-0", "z-[1]"]
  rightCls = ["left-[100%]"]
  leftCls = ["-left-[200%]"]

  resetRows() {
    this.rowTargets.forEach((row) => {
      row.classList.remove(...this.showCls)
      row.classList.add(...this.leftCls)
    })
  }

  rotateLogos() {
    const rows = this.rowTargets
    const count = rows.length

    setInterval(() => {
      let nextRow = rows[this.counter]
      let currentRow = rows[this.counter - 1]
      this.resetRows()
      nextRow.classList.remove(...this.leftCls)
      nextRow.classList.add(...this.showCls)
      this.counter++
      if (this.counter >= rows.length) {
        this.counter = 0
      }
    }, 2000)
  }
}
