import {Controller} from "stimulus"
import * as shared from "controllers-shared"

export default class extends Controller {
  static get targets() {
    return ["animate"]
  }

  connect() {
    this.animateWhenInViewport()
  }

  animateWhenInViewport() {
    /* animate something when scrolling to an specific target */
    const animateTargets = this.animateTargets
    window.addEventListener("scroll", function () {
      var bottomOfWindow = ((document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop) + window.innerHeight
      animateTargets.forEach((el) => {
        var topOfElement = el && shared.getCumulativeOffset(el).top
        if (bottomOfWindow > topOfElement) {
          if (!shared.hasClass(el, "in-viewport")) {
            shared.addClass(el, "in-viewport")
          }
        }
      })
    })
  }
}
