import {Controller} from "stimulus"
import * as shared from "controllers-shared"

export default class extends Controller {

  connect() {
    shared.onScroll()
  }

}
