import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["arch", "inner"]

  connect() {
    this.setPadding()
  }

  setPadding() {
    let archHeight = Math.floor(this.archTarget.getBoundingClientRect().height)
    this.innerTarget.style.paddingBottom = `${archHeight + 36}px`
  }
}
