import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["tab", "content", "drawer", "btnText"]

  get tabTargetsArray() {
    return this.tabTargets;
  }

  get contentTargetsArray() {
    return this.contentTargets;
  }

  showCls = ["show-tab"]
  hideRight = ["hide-right"]
  hideLeft = ["hide-left"]
  activeTabCls = ["active-tab"]

  hideTabLeft(tab) {
    tab.classList.remove(...this.hideRight, this.showCls)
    tab.classList.add(...this.hideLeft)
  }

  hideTabRight(tab) {
    tab.classList.remove(...this.hideLeft, this.showCls)
    tab.classList.add(...this.hideRight)
  }

  showTabContent(content) {
    content.classList.remove(...this.hideLeft, ...this.hideRight)
    content.classList.add(...this.showCls);
  }

  hideOtherTabs(selectedTabId, tabs) {
    tabs.forEach((tab) => {
      const tabId = tab.dataset.tabId
      if (tabId !== selectedTabId) {
      selectedTabId < tabId ? this.hideTabRight(tab) : this.hideTabLeft(tab)
      }
    })
  }

  onTabClick(e) {
    const tab = e.currentTarget
    const selectedTabId = tab.dataset.tabId
    this.btnTextTarget.innerHTML = tab.dataset.tabTitle

    const tabTargets = this.tabTargetsArray
    const contentTargets = this.contentTargetsArray

    const activeTabs = tabTargets.filter((tab) => tab.dataset.tabId === selectedTabId) 
    const activeContent = contentTargets.find((content) => content.dataset.tabId === selectedTabId)
    const inactiveTabs = tabTargets.filter((tab) => tab.dataset.tabId !== selectedTabId)
    const inactiveContents = contentTargets.filter((content) => content !== activeContent)

    // Apply active classes to both mobile and desktop tabs
    activeTabs.forEach((tab) => tab.classList.add(...this.activeTabCls))
    // Remove active classes from all other tabs
    inactiveTabs.forEach((tab) => tab.classList.remove(...this.activeTabCls))
    // Show the active tab's content
    this.showTabContent(activeContent)
    // Hide all other tabs' content and in the proper direction
    this.hideOtherTabs(selectedTabId, inactiveContents)
    
  }

  showTab(e) {
    this.onTabClick(e)
  }

  showDrawer() {
    this.drawerTarget.show()
  }

  drawerTab(e) {
    this.onTabClick(e)
    this.drawerTarget.hide()
  }
}
