import {Controller} from "stimulus"
import * as shared from "controllers-shared"

export default class extends Controller {
  static get targets() {
    return ["entry", "success", "error", "fullName", "fullNameError", "email", "emailError", "submit", "form", "other", "otherInput", "sourceChips", "wom", "womChips"]
  }

  showTarget(target) {
    target.style.display = "block"
  }

  hideTarget(target) {
    target.removeAttribute("style")
  }

  unCheckAll(radios) {
    radios.forEach((radio) => radio.removeAttribute("checked"))
  }

  selectSource(e) {
    e.preventDefault()
    e.stopPropagation()

    const sources = this.sourceChipsTarget.querySelectorAll("button")
    this.unCheckAll(sources)

    const target = e.currentTarget
    target.setAttribute("checked", true)

    const input = this.sourceChipsTarget.querySelector("input")
    input.value = target.value

    // if other show other
    this.otherSelected(target.value)

    // if wom show wom
    const womTarget = this.womTarget
    target.value === "Word of Mouth" ? this.showTarget(womTarget) : this.hideTarget(womTarget)

    // this.onChange()
  }

  otherSelected(value) {
    const otherTarget = this.otherTarget
    const inputTarget = this.otherInputTarget
    if (value === "Other") {
      // this.otherInputTarget.required = true
      this.showTarget(otherTarget)
      this.otherInputTarget.focus()
    } else {
      this.hideTarget(otherTarget)
      // this.otherInputTarget.required = false
    }
  }

  selectWoMSource(e) {
    e.preventDefault()
    e.stopPropagation()

    const woms = this.womChipsTarget.querySelectorAll("button")
    this.unCheckAll(woms)

    const target = e.currentTarget
    target.setAttribute("checked", true)

    const input = this.womChipsTarget.querySelector("input")
    input.value = target.value
  }

  onSubmit() {
    let xmlhttp = new XMLHttpRequest()
    let lead = {}
    let response
    for (let i = 0; i < this.formTarget.elements.length - 1; i++) {
      let e = this.formTarget.elements[i]
      if (e.name) {
        lead[e.name] = e.value
      }
    }
    xmlhttp.open("POST", this.formTarget.dataset.value, true)
    xmlhttp.setRequestHeader("Content-Type", "application/json")
    xmlhttp.send(JSON.stringify(lead))
    shared.addClass(this.submitTarget, "pointer-events-none")
    this.submitTarget.textContent = "Please wait..."
    xmlhttp.onreadystatechange = () => {
      if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
        this.submitTarget.textContent = "Schedule a Demo"
        const response = xmlhttp.response === "" ? xmlhttp.response : JSON.parse(xmlhttp.response)["lead/error"]
        switch (response) {
          case "lead/bad-name":
            this.showTarget(this.fullNameErrorTarget)
            this.fullNameTarget.focus()
            break
          case "lead/bad-email":
            this.showTarget(this.emailErrorTarget)
            this.emailTarget.focus()
            break
          case "lead/unidentified":
            // this.showTarget(this.emailErrorTarget)
            // shared.addClass(this.errorTarget, "show")
            break
          default:
            if (this.entryTarget != undefined) {
              shared.addClass(this.entryTarget, "hide")
            }
            shared.addClass(this.successTarget, "show") /* to test */
        }
      }
    }
  }
}
