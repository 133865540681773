import {Controller} from "stimulus"
import MicroModal from "micromodal"
import {maskInput} from "vanilla-text-mask"
import * as shared from "controllers-shared"

export default class extends Controller {

  static get targets() {
    return ["keydown",
            "entry",
            "success",
            "error",
            "errorcontent",
            "submit",
            "form"]
  }

  onSubmit() {
    shared.onSubmit(
      "hr-lead",
      this.formTarget,
      this.submitTarget,
      this.errorcontentTarget,
      this.errorTarget,
      this.successTarget,
      this.entryTarget,
      "Schedule a Demo"
    )
  }

  onSubmitKeydown(e) {
    if (e.keyCode === 13) {
      this.onSubmit()
      e.preventDefault()
      e.stopPropagation()
    }
  }

  onBackToBanzai() {
    window.history.back()
  }

}
