import {Controller} from "stimulus"

export default class extends Controller {

  connect() {
    document.body.classList.add("js-loading");
    window.addEventListener("load", () => {
      document.body.classList.remove("js-loading")
    });
  }

}
