import {Controller} from "stimulus"

export default class extends Controller {

  toggleFaq(event) {
    const target = event.currentTarget
    if (target.getAttribute("aria-expanded") === "true") {
      target.setAttribute("aria-expanded", "false")
    } else {
      target.setAttribute("aria-expanded", "true")
    }
    target.classList.toggle("show")
  }

}
