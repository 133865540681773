import {Controller} from "stimulus"
import MicroModal from "micromodal"

export default class extends Controller {

  static get targets() {
    return ["modal", "player"]
  }

  onModalClose() {
    var player = this.playerTarget
    if (player) player.innerHTML = ""
  }

  renderIFrame(src) {
    var videoPlayer = document.createElement("iframe");
    videoPlayer.id = "video-player";
    videoPlayer.src = src;
    videoPlayer.height = "315";
    videoPlayer.width = "400";
    videoPlayer.setAttribute("allowfullscreen", true);
    videoPlayer.setAttribute("allow", "encrypted-media");
    return videoPlayer;
  }

  onModalOpen() {
    if (this.modalTarget.id === "video-modal") { // onModalOpen will trigger for any modal that opens
      const player = this.playerTarget
      if (player) {
        player.appendChild(
          this.renderIFrame("https://www.youtube.com/embed/pQyfQDsyBh0?rel=0&amp;controls=1&amp;autoplay=1&amp;showinfo=0")
        );
      }
    }
  }

  connect() {
    MicroModal.init({
      disableScroll: true,
      onShow: () => this.onModalOpen(),
      onClose: () => this.onModalClose()
    });
  }
}
