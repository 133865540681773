/* state functions */

const stateList = [
  {
    id: "wy",
    name: "Wyoming"
  },
  {
    id: "wv",
    name: "West Virginia"
  },
  {
    id: "wi",
    name: "Wisconsin"
  },
  {
    id: "wa",
    name: "Washington"
  },
  {
    id: "vt",
    name: "Vermont"
  },
  {
    id: "va",
    name: "Virginia"
  },
  {
    id: "ut",
    name: "Utah"
  },
  {
    id: "tx",
    name: "Texas"
  },
  {
    id: "tn",
    name: "Tennessee"
  },
  {
    id: "sd",
    name: "South Dakota"
  },
  {
    id: "sc",
    name: "South Carolina"
  },
  {
    id: "ri",
    name: "Rhode Island"
  },
  {
    id: "pa",
    name: "Pennsylvania"
  },
  {
    id: "or",
    name: "Oregon"
  },
  {
    id: "ok",
    name: "Oklahoma"
  },
  {
    id: "oh",
    name: "Ohio"
  },
  {
    id: "nv",
    name: "Nevada"
  },
  {
    id: "nm",
    name: "New Mexico"
  },
  {
    id: "ny",
    name: "New York"
  },
  {
    id: "nj",
    name: "New Jersey"
  },
  {
    id: "nh",
    name: "New Hampshire"
  },
  {
    id: "ne",
    name: "Nebraska"
  },
  {
    id: "nd",
    name: "North Dakota"
  },
  {
    id: "nc",
    name: "North Carolina"
  },
  {
    id: "mt",
    name: "Montana"
  },
  {
    id: "ms",
    name: "Mississippi"
  },
  {
    id: "mo",
    name: "Missouri"
  },
  {
    id: "mn",
    name: "Minnesota"
  },
  {
    id: "mi",
    name: "Michigan"
  },
  {
    id: "me",
    name: "Maine"
  },
  {
    id: "md",
    name: "Maryland"
  },
  {
    id: "ma",
    name: "Massachusetts"
  },
  {
    id: "la",
    name: "Louisiana"
  },
  {
    id: "ky",
    name: "Kentucky"
  },
  {
    id: "ks",
    name: "Kansas"
  },
  {
    id: "in",
    name: "Indiana"
  },
  {
    id: "il",
    name: "Illinois"
  },
  {
    id: "id",
    name: "Idaho"
  },
  {
    id: "ia",
    name: "Iowa"
  },
  {
    id: "hi",
    name: "Hawaii"
  },
  {
    id: "ga",
    name: "Georgia"
  },
  {
    id: "fl",
    name: "Florida"
  },
  {
    id: "de",
    name: "Delaware"
  },
  {
    id: "ct",
    name: "Connecticut"
  },
  {
    id: "co",
    name: "Colorado"
  },
  {
    id: "ca",
    name: "California"
  },
  {
    id: "az",
    name: "Arizona"
  },
  {
    id: "ar",
    name: "Arkansas"
  },
  {
    id: "al",
    name: "Alabama"
  },
  {
    id: "ak",
    name: "Alaska"
  }
]

export const getStateInfo = state => {
  var clickedState = state.substring(0, 2).toLowerCase();
  return stateList.filter(function(x) {
    if (x.id === clickedState) {
      return x;
    }
  })[0];
}

const genButton = (id, onClick) => {
  var button = document.createElement("div");
  button.id = id;
  if (onClick) {
    button.addEventListener("click", onClick);
  }
  return button;
}

const renderXButton = () => {
  var button = genButton("x")
  button.appendChild(document.createElement("i"));
  return button;
}

const renderDownloadButton = pdfPath => {
  var button = genButton("download", function() {
    window.open(pdfPath)
  });
  var downloadLink = button.appendChild(document.createElement("a"));
  downloadLink.appendChild(document.createTextNode("OPEN"));
  downloadLink.setAttribute("target", "_blank");
  downloadLink.setAttribute("href", pdfPath);
  return button;
}

const initCloseButton = () => {
  document.getElementById("x").addEventListener("click", function(event) {
    event.preventDefault();
    clearSelectedState();
  });
}

const getInnerText = state => {
  var stateInfo = getStateInfo(state);
  var stateName = stateInfo.name;
  var text = document.createElement("div");
  text.id = "curriculum-text";
  var head = document.createElement("p");
  head.id = "state-head";
  head.appendChild(document.createTextNode(stateName));
  var curriculum = document.createElement("p");
  curriculum.id = "curriculum-p";
  curriculum.appendChild(document.createTextNode("See how Banzai aligns with " + stateName + "'s financial literacy curriculum."));
  text.appendChild(head);
  text.appendChild(curriculum);
  text.appendChild(renderDownloadButton("https://s3.amazonaws.com/education-production/curriculum-alignment/" + stateInfo.id + "-curriculum-alignment.pdf"));
  text.appendChild(renderXButton());
  return text;
}

const getLeft = x => {
  var left = x + 48;
  return left > 688 ? 688 : left;
}

const addCurriculumBox = (x, y, state) => {
  var curriculumBox = document.createElement("div");
  curriculumBox.id = "curriculum-box";
  curriculumBox.style.left = getLeft(x) + "px";
  curriculumBox.style.top = y + 20 + (window.pageYOffset - 280) + "px";
  curriculumBox.appendChild(getInnerText(state.id));
  document.getElementById("map").appendChild(curriculumBox);
  initCloseButton();
}

const removeCurriculumBox = () => {
  document.getElementById("map").removeChild(document.getElementById("curriculum-box"))
}

const selectState = (stateId, x, y) => {
  var selectedState = document.getElementById(stateId);
  if (selectedState.classList.contains("st0")) {
    selectedState.setAttribute("data-clicked", null);
    selectedState.classList.remove("st0");
    addCurriculumBox(x, y, selectedState);
  }
}

const clearSelectedState = () => {
  var clicked = document.querySelector("[data-clicked]");
  if (clicked) {
    clicked.classList.add("st0");
    clicked.removeAttribute("data-clicked");
    removeCurriculumBox();
  }
}

export const stateClicked = event => {
  var stateId = event.target.id;
  var stateShape = event.target.getBoundingClientRect();
  var middle = stateShape.left + ((stateShape.right - stateShape.left) / 2);
  clearSelectedState();
  selectState(stateId, middle, (stateShape.bottom * .1));
}

// Select and Download button

export const onSelectionsComplete = () => {
  var selectionState = document.getElementById("select-state");
  var selectionButton = document.getElementById("select-download");
  var selectionContainer = document.getElementById("select-curriculum");
  if (selectionState.value) {
    selectionButton.disabled = false;
  } else {
    selectionButton.disabled = true;
  }
}


export const onDownloadClick = () => {
  window.open("https://s3.amazonaws.com/education-production/curriculum-alignment/" + document.getElementById("select-state").value.toLowerCase() + "-curriculum-alignment.pdf");
}
