import {Controller} from "stimulus"
import {getStateInfo} from "teachbanzai/curriculum"

export default class extends Controller {
  static targets = ["map", "popup", "popupTitle", "popupText", "popupDownload", "select", "download"]

  connect() {
    console.log("connnected", this.element)
    this.fetchMap()
  }

  fetchMap() {
    const mapTarget = this.mapTarget
    fetch("https://static-app-misc.banzai.org/img/curriculum-page/united-states-map.svg")
      .then((response) => response.text())
      .then((html) => {
        mapTarget.innerHTML = html
      })
  }

  clearSelectedState() {
    var clicked = document.querySelector("[data-clicked]")
    if (clicked) {
      clicked.removeAttribute("data-clicked")
      this.popupTarget.removeAttribute("style")
    }
  }

  updatePopup(state) {
    const stateInfo = getStateInfo(state)
    var stateName = stateInfo.name
    var stateId = stateInfo.id
    const popupDownload = this.popupDownloadTarget
    this.popupTitleTarget.textContent = stateName
    this.popupTextTarget.textContent = `See how Banzai aligns with ${stateName}'s financial literacy curriculum.`
    popupDownload.href = `https://s3.amazonaws.com/education-production/curriculum-alignment/${stateId}-curriculum-alignment.pdf`
    popupDownload.download = `${stateId}-state-standards.pdf`
  }

  getLeft(x) {
    var left = x + 48;
    return left > 688 ? 688 : left;
  }

  showPopup(state, stateShape) {
    /* first update popup contents */
    this.updatePopup(state)

    /* second, show popup */
    let x = stateShape.left + (stateShape.right - stateShape.left) / 2
    let y = stateShape.bottom * 0.1
    const popupTarget = this.popupTarget
    popupTarget.style.left = this.getLeft(x) + "px"
    popupTarget.style.top = y + 20 + (window.pageYOffset - 280) + "px";
    popupTarget.style.display = "grid"
  }

  onStateClick(e) {
    this.clearSelectedState()
    const selectedState = e.target
    const stateShape = selectedState.getBoundingClientRect()
    selectedState.setAttribute("data-clicked", null);
    this.showPopup(selectedState.id, stateShape);
  }

  onChange() {
    const link = this.downloadTarget
    const selectValue = this.selectTarget.value
    if (selectValue) {
      let state = selectValue.toLowerCase()
      link.removeAttribute("disabled")
      link.disabled = false
      link.href = `https://s3.amazonaws.com/education-production/curriculum-alignment/${state}-curriculum-alignment.pdf`
      link.download = `${state}-state-standards.pdf`
     } else {
      link.setAttribute("disabled", "true")
     } 
  }
  
}
