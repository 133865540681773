import "./runtime"
import animate_controller from "./teachbanzai/controllers/animate_controller.js"
import arch from "./teachbanzai/controllers/arch-controller.js"
import branding_controller from "./teachbanzai/controllers/branding_controller.js"
import checkbox_controller from "./teachbanzai/controllers/checkbox_controller.js"
import curriculum_controller from "./teachbanzai/controllers/curriculum_controller.js"
import drawer_controller from "./teachbanzai/controllers/drawer_controller.js"
import faq_controller from "./teachbanzai/controllers/faq_controller.js"
import header_controller from "./teachbanzai/controllers/header_controller.js"
import hr_lead_controller from "./teachbanzai/controllers/hr-lead_controller.js"
import impact_controller from "./teachbanzai/controllers/impact_controller.js"
import lazy_video_controller from "./teachbanzai/controllers/lazy-video_controller.js"
import lead_controller from "./teachbanzai/controllers/lead_controller.js"
import loaded_controller from "./teachbanzai/controllers/loaded_controller.js"
import lottie_controller from "./teachbanzai/controllers/lottie_controller.js"
import nav_controller from "./teachbanzai/controllers/nav_controller.js"
import remote_learning_controller from "./teachbanzai/controllers/remote-learning_controller.js"
import reviews_controller from "./teachbanzai/controllers/reviews_controller.js"
import scroll_to_top_controller from "./teachbanzai/controllers/scroll-to-top_controller.js"
import segway_lead_controller from "./teachbanzai/controllers/segway-lead_controller.js"
import sponsor_lead_controller from "./teachbanzai/controllers/sponsor-lead_controller.js"
import sponsors_controller from "./teachbanzai/controllers/sponsors_controller.js"
import sticky_header_controller from "./teachbanzai/controllers/sticky-header_controller.js"
import tabs_controller from "./teachbanzai/controllers/tabs_controller.js"
import toggle_controller from "./teachbanzai/controllers/toggle_controller.js"
import truncate_controller from "./teachbanzai/controllers/truncate_controller.js"
import video_controller from "./teachbanzai/controllers/video_controller.js"
import pd_video_controller from "./teachbanzai/controllers/pd_video_controller.js"

import {Application} from "stimulus"
import whatInput from "what-input"

const application = Application.start()

application.register("animate", animate_controller)
application.register("arch", arch)
application.register("branding", branding_controller)
application.register("checkbox", checkbox_controller)
application.register("curriculum", curriculum_controller)
application.register("drawer", drawer_controller)
application.register("faq", faq_controller)
application.register("header", header_controller)
application.register("hr-lead", hr_lead_controller)
application.register("impact", impact_controller)
application.register("lazy-video", lazy_video_controller)
application.register("lead", lead_controller)
application.register("loaded", loaded_controller)
application.register("lottie", lottie_controller)
application.register("nav", nav_controller)
application.register("remote-learning", remote_learning_controller)
application.register("reviews", reviews_controller)
application.register("scroll-to-top", scroll_to_top_controller)
application.register("segway-lead", segway_lead_controller)
application.register("sponsor-lead", sponsor_lead_controller)
application.register("sponsors", sponsors_controller)
application.register("sticky-header", sticky_header_controller)
application.register("tabs", tabs_controller)
application.register("toggle", toggle_controller)
application.register("truncate", truncate_controller)
application.register("video", video_controller)
application.register("pd-video", pd_video_controller)

