import {Controller} from "stimulus"
import * as shared from "controllers-shared"

export default class extends Controller {

  static get targets() {
    return [ "anchor", "expand" ]
  }

  connect() {
    this.addRemoteClass()
  }

  addRemoteClass() {
    shared.addClass(document.body, "remote-learning")
  }

  onExpandClick() {
    this.expandTargets.forEach(el => {
      el.classList.toggle("expand")
    })
  }

  onAnchorClick(e) {
    const id = e.target.id.substring(7)
    this.openCheck(id)
  }

  openCheck(id) {
    this.anchorTargets.forEach(el => {
      if (el.id === id) {
        el.checked = false
      }
    })
  }

}
