import {Controller} from "stimulus"
import {fetchStaticAsset} from "../../controllers-shared"
import {hide, show} from "controllers-shared"

export default class extends Controller {
  static targets = ["illustration", "schools", "button"]

  fetchIllustrations() {
    this.illustrationTargets.forEach((element) => {
      fetchStaticAsset(element, element.getAttribute("svg-path"))
    })
  }

  hideSchools = () => (
    this.schoolsTarget.childNodes.forEach((el, idx) => idx > 7 ? hide(el) : show(el))
  )

  connect() {
    this.hideSchools()
    this.fetchIllustrations()
  }

  showAllSchools = (schools) => (
    this.schoolsTarget.childNodes.forEach((el) => show(el))
  )

  toggleList() {
    let btnText = this.buttonTarget.childNodes[0].childNodes[1]

    if (btnText.textContent === "View More") {
      btnText.textContent = "View Less"
      this.showAllSchools()
    } else {
      btnText.textContent = "View More"
      this.hideSchools()
    }
  }
}
