import {Controller} from "stimulus"

const reviews = [{
  stars: 5,
  reviewer: "Teacher, Hazleton Area School District",
  where: "Wyoming",
  feedback: "“I love listening to the student conversations as they work through the assignments and realize how “hard” life can be even if they are only completing a simulation.”"
}, {
  stars: 5,
  reviewer: "Teacher, Magnet Cove High School",
  where: "Arkansas",
  feedback: "“My students love the program, I like the easy grading access, the scenarios are fantastic, the software was easy to use, administer assignments and grade and monitor progress.”"
}, {
  stars: 4,
  reviewer: "Teacher, Auburn Junior High",
  where: "New York",
  feedback: "“There is no doubt in my mind that when students use technology to learn, it becomes fun, and Banzai is exactly that!”"
}, {
  stars: 5,
  reviewer: "Teacher, Quincy Senior High School",
  where: "Illinois",
  feedback: "“The kids like that they did not have to watch videos like other programs they've done, and instead were able to interactively participate in the entire process.”"
}]

export default class extends Controller {
  static targets = ["review"];
  counter = 0


  connect() {
    this.rotateReviews()
  }

  showCls = ["top-0", "animate-fade-in", "z-[1]"]
  hideCls = ["top-[100%]", "opacity-0"]

  hideReviews() {
    this.reviewTargets.forEach((review) => {
      review.classList.remove(...this.showCls)
      review.classList.add(...this.hideCls)
    })
  }

  rotateReviews() {
    const reviews = this.reviewTargets
    let counter = this.counter
    setInterval(() => {
      let visibleReview = reviews[counter]
      this.hideReviews()
      visibleReview.classList.remove(...this.hideCls)
      visibleReview.classList.add(...this.showCls)
      counter++;
      if (counter >= reviews.length) {
        counter = 0;
      }
    }, 5000)
  }

}
