import {Controller} from "stimulus"
import {scrollTopAnimate} from "shared/scroll-top-animate"

export default class extends Controller {

  scrollTop() {
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

}
