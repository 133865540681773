import {Controller} from "stimulus"
import * as shared from "controllers-shared"

export default class extends Controller {
  connect() {
    this.load()
  }

  fetchLogo(string, element) {
    fetch(shared.getMetaContentByName(string))
      .then((response) => response.text())
      .then((html) => {
        element.innerHTML = html
      })
  }

  /* Different logo sizes */
  // tall fetch("https://static-app-logos.teachbanzai.com/black/ecu.svg")
  // tall fetch("https://static-app-logos.teachbanzai.com/black/verabank.svg.svg")
  // regular fetch("https://static-app-logos.teachbanzai.com/black/isucu.svg")
  // large fetch("https://static-app-logos.teachbanzai.com/black/ticreditunion.svg")
  // x-large fetch("https://static-app-logos.teachbanzai.com/black/gucu.svg")
  // x-large fetch("https://static-app-logos.teachbanzai.com/black/fccu.svg")
  // xx-large fetch("https://static-app-logos.teachbanzai.com/black/anchorfirstfederal.svg")
  // xx-large fetch("https://static-app-logos.teachbanzai.com/black/htfffcu.svg.svg")

  load() {
    this.fetchLogo("logo-url", this.element)
  }
}
