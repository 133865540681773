import {Controller} from "stimulus"
import * as shared from "controllers-shared"

export default class extends Controller {
  static get targets() {
    return [ "menu", "nav" ]
  }

  initialize() {
    // this.close()
  }

  open() {
    shared.showMenu(this.menuTargets, true)
  }

  close() {
    shared.showMenu(this.menuTargets, false)
  }

  getScreenHeight() {
    return window.innerHeight
  }

  openNav(e) {
    shared.removeClass(this.navTarget, "left-full")
    shared.addClass(this.navTarget, "left-0")
  }

  closeNav() {
    this.navTargets.forEach(el => {
      shared.removeClass(el, "left-0")
      shared.addClass(el, "left-full")
    })
  }

}
