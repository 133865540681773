import {Controller} from "stimulus"

const close = () => {
  const overlayTarget = document.querySelector("[data-nav-open]")
  overlayTarget.dataset.navOpen = "false"
  overlayTarget.removeAttribute("style")
  document.body.removeAttribute("style")
  document.removeEventListener("click", clickOutside)
}

const clickOutside = (event) => {
  const target = event.target
  const menuTarget = document.querySelector("[data-target='nav.menu']")
  const hamburgerTarget = document.querySelector("[data-target='nav.hamburger']")

  if (!menuTarget.contains(target) && !hamburgerTarget.contains(target)) {
    close()
  }
}

export default class extends Controller {
  static targets = ["overlay", "menu", "hamburger", "mdropdown"];
  isOpen = this.overlayTarget.dataset.navOpen;

  initialize() {
    this.close()
  }

  connect() {
    this.onScroll()
  }

  open() {
    if (this.isOpen === "false") {
      const overlay = this.overlayTarget
      overlay.dataset.navOpen = "true";
      overlay.style.height = `100vh`;
      overlay.style.overflow = "hidden";
      document.body.style.overflow = "hidden";
      document.addEventListener("click", clickOutside)
    }
  }

  close() {
    close()
  }

  openDropdown(e) {
    const dropdown = e.currentTarget.parentElement
    const inactiveDropdowns = this.mdropdownTargets.filter((d) => d !== dropdown)
    inactiveDropdowns.forEach((d) => d.removeAttribute("open"))
  }

  onScroll() {
    window.addEventListener("scroll", () => {
      const nav = this.element
      if (document.documentElement.scrollTop > 80 || document.body.scrollTop > 80) {
        nav.style.boxShadow = "0px 2px 8px 0px rgba(29, 23, 38, 0.10)"
      } else {
        nav.style.boxShadow = "none"
      }
    })  
  }

}

